import { useEffect, useState } from "react";
import ModalCalculator from "./ModalCalculator";
import ModalCities from "./ModalCities";
import Login from "./Login";
import ModalCabinet from "./ModalCabinet";
import "./Header.scss";
import CompletionRegistration from "../completion-registration/CompletionRegistration";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BsFillTelephoneFill, BsFillCartFill } from "react-icons/bs";
// import { CiMenuBurger } from 'react-icons/ci'
// import { IoIosClose } from 'react-icons/io'
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";

const Header = () => {
  const [modal, setModal] = useState(false);
  const { user } = useSelector(state => state.user);
  const [isToken, setIsToken] = useState(null);
  const handleOpenModal = value => {
    // setModal(value)
  };

  useEffect(() => {
    setIsToken(!!window.localStorage.getItem("token"));
    const closeFormRegistration = window.localStorage.getItem("formClose");
    if (
      closeFormRegistration !== "true" &&
      user.items.length != 0 &&
      user.items.address.length == 0
    ) {
      setModal(true);
    }
  }, [user]);

  const handleCloseModal = () => {
    setModal(false);
    window.localStorage.setItem("formClose", true);
    console.log("close");
  };

  // Меняет цвет шапки при скролле
  const [navScroll, setNavScroll] = useState(false);
  const showScroll = () => {
    if (window.scrollY > 80) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };
  window.addEventListener("scroll", showScroll);

  const [modalMenu, setModalMenu] = useState(false);

  const handleOpenModalMenu = () => {
    setModalMenu(true);
  };

  const [isOpen, setIsOpen] = useState(false); // Состояние для открытия/закрытия меню
  const [isScrolled, setIsScrolled] = useState(false); // Состояние для отслеживания прокрутки

  useEffect(() => {
    // Функция для обработки события прокрутки страницы
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      // Если прокрутка больше 80px, устанавливаем состояние isScrolled в true, иначе - false
      setIsScrolled(scrollTop > 80);
    };

    // Добавляем слушателя события прокрутки при монтировании компонента
    window.addEventListener("scroll", handleScroll);

    // Удаляем слушателя события прокрутки при размонтировании компонента
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isTransitioning, setIsTransitioning] = useState(false); // Состояние для отслеживания анимации

  // Функция для переключения состояния меню
  const toggleMenu = () => {
    setIsTransitioning(true); // Устанавливаем флаг, что началась анимация
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Если меню открыто, устанавливаем флаг анимации в false после завершения анимации (300 мс)
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        setIsTransitioning(false);
      }, 300); // 300 миллисекунд - время анимации, должно соответствовать времени transition в CSS
      return () => clearTimeout(timeoutId);
    } else {
      // Если меню закрыто, устанавливаем флаг анимации в true перед началом анимации
      setIsTransitioning(true);
    }
  }, [isOpen]);

  const handleClickInsideMenu = event => {
    event.stopPropagation(); // Предотвращаем всплытие события
  };

  return (
    <header
      className={
        navScroll ? "header__wrapper header__wrapper-bg" : "header__wrapper"
      }>
      <nav className="nav__main">
        <div className={`burger-menu ${isScrolled ? "scrolled" : ""}`}>
          {/* Иконка меню */}
          <div
            className={`menu-icon ${isOpen ? "open" : ""}`}
            onClick={toggleMenu}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>

          {/* Модальное окно */}
          {isOpen && (
            <div className="modal">
              <div
                className={`header__modal-menu ${
                  isTransitioning ? "" : "header__modal-menu-open"
                }`}
                onClick={handleClickInsideMenu}>
                <ModalCities additionalClass="nav__citie-mob" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "100%",
                    padding: "10px 0",
                  }}>
                  <a
                    href={`tel:89152640011`}
                    className="nav__container-info-phone">
                    {" "}
                    <BsFillTelephoneFill color="#5e92ff" />
                  </a>
                  <a
                    href="https://wa.me/+79152640011"
                    target="_blank"
                    rel="noreferrer"
                    className="nav__container-info-phone"
                    style={{
                      fontSize: "20px",
                    }}>
                    {" "}
                    <FaWhatsapp color="#5e92ff" />
                  </a>
                  <a
                    href="https://t.me/tvoyauborka_RU"
                    target="_blank"
                    rel="noreferrer"
                    className="nav__container-info-phone"
                    style={{
                      fontSize: "20px",
                    }}>
                    {" "}
                    <FaTelegram color="#5e92ff" />
                  </a>
                </div>
                <ul className="header__modal-menu-list" onClick={toggleMenu}>
                  <li>
                    <Link
                      to={"/calculator"}
                      onClick={() => setModalMenu(false)}>
                      Заказать уборку
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/uslugi/uborka-pomesheniy/office/"}
                      onClick={() => setModalMenu(false)}>
                      Уборка офиса
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/calculator"}
                      onClick={() => setModalMenu(false)}>
                      Уборка квартиры
                    </Link>
                  </li>
                  <li>
                    <Link to={"/feedback"} onClick={() => setModalMenu(false)}>
                      Отзывы
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="nav__container">
          {/* <span className="nav__menu-mobile">
            {navScroll ? (
              modalMenu ? (
                <IoIosClose
                  color="white"
                  className="nav__menu-mobile-close"
                  onClick={() => setModalMenu(false)}
                />
              ) : !isToken ? (
                <CiMenuBurger
                  color="white"
                  className="nav__menu-mobile-burger"
                  onClick={handleOpenModalMenu}
                />
              ) : null
            ) : modalMenu ? (
              <IoIosClose
                className="nav__menu-mobile-close"
                onClick={() => setModalMenu(false)}
              />
            ) : !isToken ? (
              <CiMenuBurger
                className="nav__menu-mobile-burger"
                onClick={handleOpenModalMenu}
              />
            ) : null}
          </span> */}
          <ModalCalculator navScroll={navScroll} />
          <ModalCities navScroll={navScroll} />
        </div>
        {modal ? (
          <CompletionRegistration
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
          />
        ) : // <CompletionRegistration openModal={handleOpenModal} handleCloseModal={handleCloseModal} />
        null}
        <span
          style={{
            display: "flex",
          }}>
          <div className="nav__container-info">
            {!isToken ? (
              <span>
                <span
                  className={
                    navScroll
                      ? "nav__container-time-scroll"
                      : "nav__container-time"
                  }>
                  Ежедневно с 08:00 до 00:00
                </span>
                <span
                  className={
                    navScroll
                      ? "nav__container-phone-scroll"
                      : "nav__container-phone"
                  }>
                  +7 915 264-00-11
                </span>
              </span>
            ) : null}

            {!isToken ? (
              <Link
                className={
                  navScroll
                    ? "nav__container-order-scroll"
                    : "nav__container-order"
                }
                to={"/calculator"}>
                Заказать онлайн
              </Link>
            ) : null}
          </div>
          <span
            style={{
              display: "flex",
            }}>
            <div className="nav__container-info-mobile">
              {!isToken ? (
                navScroll ? (
                  <a
                    href={`tel:89152640011`}
                    className="nav__container-info-phone nav__container-info-phone-scroll">
                    <BsFillTelephoneFill color="#5e92ff" />
                  </a>
                ) : (
                  <a
                    href={`tel:89152640011`}
                    className="nav__container-info-phone">
                    <BsFillTelephoneFill color="#5e92ff" />
                  </a>
                )
              ) : null}

              {!isToken ? (
                navScroll ? (
                  <Link
                    to={"/calculator"}
                    className="nav__container-info-phone  nav__container-info-phone-scroll">
                    <BsFillCartFill size={"1.2em"} color="#5e92ff" />
                  </Link>
                ) : (
                  <Link
                    to={"/calculator"}
                    className="nav__container-info-phone">
                    <BsFillCartFill size={"1.2em"} color="#5e92ff" />
                  </Link>
                )
              ) : null}
            </div>
            {!isToken ? <Login /> : <ModalCabinet />}
          </span>
        </span>
      </nav>
      {modalMenu ? (
        <div className="header__modal-menu">
          <ModalCities additionalClass="nav__citie-mob" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              padding: "10px 0",
            }}>
            <a href={`tel:89152640011`} className="nav__container-info-phone">
              {" "}
              <BsFillTelephoneFill color="#5e92ff" />
            </a>
            <a
              href="https://wa.me/+79152640011"
              target="_blank"
              rel="noreferrer"
              className="nav__container-info-phone"
              style={{
                fontSize: "20px",
              }}>
              {" "}
              <FaWhatsapp color="#5e92ff" />
            </a>
            <a
              href="https://t.me/tvoyauborka_RU"
              target="_blank"
              rel="noreferrer"
              className="nav__container-info-phone"
              style={{
                fontSize: "20px",
              }}>
              {" "}
              <FaTelegram color="#5e92ff" />
            </a>
          </div>
          <ul className="header__modal-menu-list">
            <li>
              <Link to={"/calculator"} onClick={() => setModalMenu(false)}>
                Заказать уборку
              </Link>
            </li>
            <li>
              <Link
                to={"/uslugi/uborka-pomesheniy/office/"}
                onClick={() => setModalMenu(false)}>
                Уборка офиса
              </Link>
            </li>
            <li>
              <Link to={"/calculator"} onClick={() => setModalMenu(false)}>
                Уборка квартиры
              </Link>
            </li>
            <li>
              <Link to={"/feedback"} onClick={() => setModalMenu(false)}>
                Отзывы
              </Link>
            </li>
          </ul>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
