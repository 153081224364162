import { useState, memo } from "react";
import Timetable from "../timetable/Timetable";
import MyOrders from "../my-orders/MyOrders";
import CompletedServices from "../completed-services/CompletedServices";
import PaymentHistory from "../payment-history/PaymentHistory";
import Settings from "../settings/Settings";
// import Requisites from "../requisites/Requisites";
import LinksCalculator from "../links-calculator/LinksCalculator";
import "./Tabs.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Tabs = memo(() => {
  const [toggleState, setToggleState] = useState(1);
  // const toggleTab = index => {
  //   setToggleState(index);
  // };

  // Логика для переключения вкладок на мобильной версии
  const isMobile = window.innerWidth <= 768; // Простая проверка на мобильное устройство
  const toggleTab = index => {
    setToggleState(index);
  };

  // Настройки для слайдера
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1.0,
    slidesToScroll: 1,
    afterChange: current => setToggleState(current + 1), // Обновление состояния при переключении слайдов
  };

  return (
    <section>
      {!isMobile && ( // Вкладки для десктопной версии
        <ul className="tabs__items">
          <li
            onClick={() => toggleTab(1)}
            className={
              toggleState === 1 ? "tab__item tab__item-active" : "tab__item"
            }>
            Расписание
          </li>
          <li
            onClick={() => toggleTab(2)}
            className={
              toggleState === 2 ? "tab__item tab__item-active" : "tab__item"
            }>
            Завершенные услуги
          </li>
          <li
            onClick={() => toggleTab(3)}
            className={
              toggleState === 3 ? "tab__item tab__item-active" : "tab__item"
            }>
            История платежей
          </li>
          <li
            onClick={() => toggleTab(5)}
            className={
              toggleState === 5 ? "tab__item tab__item-active" : "tab__item"
            }>
            Настройки
          </li>
          <li
            onClick={() => toggleTab(6)}
            className={
              toggleState === 6 ? "tab__item tab__item-active" : "tab__item"
            }>
            Выбор услуги
          </li>
        </ul>
      )}

      <div
        className="tab__content"
        style={{
          background: "white",
        }}>
        {isMobile ? (
          // Слайдер для мобильной версии
          <Slider {...sliderSettings}>
            <div>
              <div className="tab__content-title">Расписание</div>
              <MyOrders />
            </div>
            <div>
              <div className="tab__content-title">Завершенные услуги</div>
              <CompletedServices />
            </div>
            <div>
              <div className="tab__content-title">История платежей</div>
              <PaymentHistory />
            </div>
            <div>
              <div className="tab__content-title">Настройки</div>
              <Settings />
            </div>
            <div>
              <div className="tab__content-title">Выбор услуги</div>
              <LinksCalculator />
            </div>
          </Slider>
        ) : (
          // Обычные табы для десктопной версии
          <>
            <div
              className={
                toggleState === 1 ? "content tab__content-active" : "content"
              }>
              <MyOrders />
            </div>

            <div
              className={
                toggleState === 2 ? "content tab__content-active" : "content"
              }>
              <CompletedServices />
            </div>

            <div
              className={
                toggleState === 3 ? "content tab__content-active" : "content"
              }>
              <PaymentHistory />
            </div>

            <div
              className={
                toggleState === 5 ? "content tab__content-active" : "content"
              }>
              <Settings />
            </div>

            <div
              className={
                toggleState === 6 ? "content tab__content-active" : "content"
              }>
              <LinksCalculator />
            </div>
          </>
        )}
      </div>
    </section>
  );
});

export default Tabs;
